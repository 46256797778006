<template>
  <ul class="kt-menu__nav">

    <template v-for="(menu, i) in menuItems">
      <KTMenuItem v-if="!menu.section" :menu="menu" :key="i"></KTMenuItem>
      <KTMenuSection v-if="menu.section" :menu="menu" :key="i"></KTMenuSection>
    </template>
  </ul>
</template>

<script>
import KTMenuItem from "@/views/theme/aside/MenuItem.vue";
import KTMenuSection from "@/views/theme/aside/MenuSection.vue";
import menuConfig from "@/common/config/menu.config.json";
import Website from "@/services/Websites/WebsiteService";

const WebsiteService = new Website();
import WebsiteSetting from "@/services/Websites/WebsiteSettingService";

const WebsiteSettingService = new WebsiteSetting();
export default {
  name: "KTAsideMenu",
  data() {
    return {
      isStore: 0,
      directoryObject: {
        title: "Directory",
        root: true,
        icon: "flaticon2-box-1",
        page: "organizations"
      },
      menuItems: [],
      defaultValue: [{
        title: "Dashboard",
        root: true,
        icon: "flaticon2-architecture-and-city",
        page: "dashboard",
        translate: "MENU.DASHBOARD",
        bullet: "dot"
      },
        {
          title: "Directory",
          root: true,
          icon: "flaticon2-box-1",
          page: "organizations"
        },

        {
          title: "Domains",
          root: true,
          icon: "flaticon2-browser-1",
          page: "domains/manage"
        },
        {
          title: "Products",
          root: true,
          icon: "flaticon2-setup",
          page: "store/products"
        },
        {
          title: "Catalogs",
          root: true,
          icon: "flaticon2-files-and-folders",
          page: "store/catalog"
        },
        {
          title: "Customers",
          root: true,
          icon: "flaticon2-group",
          page: "store/customers/all"
        },
        {
          title: "Orders",
          root: true,
          icon: "flaticon2-sheet",
          page: "store/orders"
        },
        {
          title: "Coupons",
          root: true,
          icon: "flaticon2-open-box",
          page: "coupon-code"
        },
        {
          title: "Payments",
          root: true,
          icon: "flaticon-price-tag",
          page: "store/payment-gateway"
        }, {
          title: "Shortcode",
          root: true,
          icon: "flaticon-price-tag",
          page: "short-code"
        }
      ]
    };
  },
  components: {
    KTMenuItem,
    KTMenuSection
  },
  watch: {
    $route(to, from, next) {
      if (this.domainName) {
        this.getWebsite();
      } else {
        this.testRoute();

      }
    }
  },
  updated() {
    // if (this.routeName == 'orders-list') {
    //     this.menuItems.push({
    //         "title": "new Directory",
    //         "root": true,
    //         "icon": "flaticon2-box-1",
    //         "page": "organizations"
    //     })
    // } else {
    //     this.menuItems = this.defaultValue;
    // }

  },
  methods: {
    testRoute() {
      if (this.isWebsite) {
        this.menuItems = [{
          title: "Dashboard",
          root: true,
          icon: "flaticon2-architecture-and-city",
          page: "dashboard",
          translate: "MENU.DASHBOARD",
          bullet: "dot"
        },
          {
            section: "CMS"
          },
          {
            title: "Pages",
            root: true,
            icon: "flaticon2-file",
            page: this.domainName.domainname ?
                "websites/pages/" + this.domainName.domainname : "websites/pages/" + this.domainName.domain_name
          },
          {
            title: "Blogs",
            root: true,
            icon: "flaticon2-files-and-folders",
            page: this.domainName.domainname ?
                "websites/blogs/" + this.domainName.domainname : "websites/blogs/" + this.domainName.domain_name
          },
          {
            title: "Menu",
            root: true,
            icon: "fas fa-bars",
            page: this.domainName.domainname ?
                "websites/menu/" + this.domainName.domainname : "websites/menu/" + this.domainName.domain_name
          },
          {
            title: "Media",
            root: true,
            icon: "flaticon2-image-file",
            page: this.domainName.domainname ?
                "websites/media/" + this.domainName.domainname : "websites/media/" + this.domainName.domain_name
          },

          {
            title: "Notice",
            root: true,
            icon: "flaticon-speech-bubble",
            page: this.domainName.domainname ?
                "websites/" + this.domainName.domainname + '/notice' : "websites/" + this.domainName.domain_name + '/notice'
          },
          {
            title: "SEO",
            root: true,
            icon: "flaticon-search-1",
            page: this.domainName.domainname ?
                "websites/seo/" + this.domainName.domainname : "websites/seo/" + this.domainName.domain_name
          },

          {
            title: "Settings",
            root: true,
            icon: "flaticon2-settings",
            page: this.domainName.domainname ?
                "websites/settings/" + this.domainName.domainname : "websites/settings/" + this.domainName.domain_name
          },
          {
            title: "Layouts",
            root: true,
            icon: "flaticon2-group",
            page: this.domainName.domainname ?
                "websites/layouts/" + this.domainName.domainname : "websites/layouts/" + this.domainName.domain_name
          },
          {
            section: "Store Management"
          },

          {
            title: "Store",
            root: true,
            icon: "flaticon-cart",
            page: this.domainName.domainname ?
                "websites/ecommerce/" + this.domainName.domainname : "websites/ecommerce/" + this.domainName.domain_name
          },
          {
            title: "Storefront",
            root: true,
            icon: "flaticon-home",
            page: this.domainName.domainname ?
                "websites/" + this.domainName.domainname + "/storefront" : "websites/" +
                this.domainName.domain_name +
                "/storefront"
          },

          {
            title: "Shipping",
            root: true,
            icon: "flaticon-truck",
            page: this.domainName.domainname ?
                "websites/" + this.domainName.domainname + "/shipping" : "websites/" +
                this.domainName.domain_name +
                "/shipping"
          },

          {
            title: "Currency",
            root: true,
            icon: "flaticon-price-tag",
            page: this.domainName.domainname ?
                "websites/" + this.domainName.domainname + "/currency" : "websites/" + this.domainName.domain_name + "/currency"
          },
          {
            title: "Invoicing",
            root: true,
            icon: "flaticon-file",
            page: this.domainName.domainname ?
                "websites/" + this.domainName.domainname + "/invoice" : "websites/" + this.domainName.domain_name + "/invoice"
          },
          {
            section: "Plugins"
          },
          {
            title: "Popup",
            root: true,
            icon: "flaticon-search-1",
            page: this.domainName.domainname ?
                "websites/" + this.domainName.domainname + "/pop_up" : "websites/" + this.domainName.domain_name + "/pop_up"
          },
          {
            title: "Facebook Chat",
            root: true,
            icon: "fab fa-facebook-messenger",
            page: this.domainName.domainname ?
                "websites/" + this.domainName.domainname + "/fb-chat" : "websites/" + this.domainName.domain_name + "/fb-chat"
          },
          {
            title: "Google Analytics",
            root: true,
            icon: "fas fa-chart-bar",
            page: this.domainName.domainname ?
                "websites/" + this.domainName.domainname + "/google-analytics" : "websites/" + this.domainName.domain_name + "/google-analytics"
          },
          {
            title: "FB Pixel",
            root: true,
            icon: "fas fa-code",
            page: this.domainName.domainname ?
                "websites/" + this.domainName.domainname + "/pixel" : "websites/" + this.domainName.domain_name + "/pixel"
          },
          {
            title: "Instagram",
            root: true,
            icon: "fab fa-instagram",
            page: this.domainName.domainname ?
                "websites/" + this.domainName.domainname + "/instagram" : "websites/" + this.domainName.domain_name + "/instagram"
          },
          {
            title: "Form Builder",
            root: true,
            icon: "flaticon-doc",
            page: this.domainName.domainname ?
                "websites/custom-form/" + this.domainName.domainname : "websites/custom-form/" + this.domainName.domain_name
          },

          {
            section: "Addons"
          },

          {
            title: "Email",
            root: true,
            icon: "flaticon-email",
            page: this.domainName.domainname ?
                "websites/mail/zoho/account/manage/" +
                this.domainName.domainname : "websites/mail/zoho/account/manage/" +
                this.domainName.domain_name
          },
          {
            title: "SSL",
            root: true,
            icon: "flaticon-security",
            page: this.domainName.domainname ?
                "websites/magage-ssl-certificate/" + this.domainName.domainname : "websites/magage-ssl-certificate/" + this.domainName.domain_name
          },
          {
            title: "Troubleshoot",
            root: true,
            icon: "fa fa-ambulance",
            page: this.domainName.domainname ?
                "websites/" + this.domainName.domainname + "/troubleshoot" : "websites/" +
                this.domainName.domain_name +
                "/troubleshoot"
          }

        ];
        if (this.isStore == 0) {
          this.menuItems.splice(10, 6);
        }
        // console.log(this.menuItems)

      } else {
        this.menuItems = [{
          title: "Dashboard",
          root: true,
          icon: "flaticon2-architecture-and-city",
          page: "dashboard",
          translate: "MENU.DASHBOARD",
          bullet: "dot"
        },

          {
            title: "Domains",
            root: true,
            icon: "flaticon2-browser-1",
            page: "domains/manage"
          },
          {
            title: "Products",
            root: true,
            icon: "flaticon2-setup",
            page: "store/products"
          },
          {
            title: "Catalogs",
            root: true,
            icon: "flaticon2-files-and-folders",
            page: "store/catalog"
          },
          {
            title: "Customers",
            root: true,
            icon: "flaticon2-group",
            page: "store/customers/all"
          },
          {
            title: "Orders",
            root: true,
            icon: "flaticon2-sheet",
            page: "store/orders/all"
          },
          {
            title: "Coupons",
            root: true,
            icon: "flaticon2-open-box",
            page: "coupon-code"
          },
          {
            title: "Payment Method",
            root: true,
            icon: "flaticon-price-tag",
            page: "store/payment-gateway"
          },
          {
            title: "Brand Manager",
            root: true,
            icon: "fas fa-bullseye",
            page: "store/brand-manager"
          }, {
            title: "Shortcode",
            root: true,
            icon: "fas fa-tools",
            page: "short-code"
          }
        ];
        // if (this.currentUser.country_id != null && this.currentUser.country_id != 155 && this.currentUser.country_id != 14) {
        //     this.menuItems.splice(1, 1);
        // }
      }

    },
    getWebsite() {
      if (this.domainName.domainname != undefined) {
        WebsiteSettingService.isStore(this.domainName.domainname ? this.domainName.domainname : this.domainName.domain_name).then(res => {
          this.isStore = res.data;
          this.testRoute();
        });
      } else {
        this.menuItems = menuConfig.aside.items;
      }
    },
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
    domainName() {
      if (
          (this.$route.params && this.$route.params.domainname) ||
          this.$route.params.domainname
      ) {
        return this.$route.params;
      }
      return false;
    },

    routePath() {
      return this.$route.path;
    },
    route() {
      let route = this.routePath.split("/");
      return route;
    },
    isWebsite() {
      if (this.routeName == "website-trash-dashboard")
        return false;
      else if (this.route[1] == "websites" && this.routeName != "website-dashboard" && this.routeName != "manage-domains" && this.routeName != "quicksetup")
        return true;
      else return false;
    },

    currentUser() {
      return this.$store.getters.currentUser;
    },

  },
  mounted() {
    this.menuItems = menuConfig.aside.items;
    this.getWebsite();

    // this.defaultValue = menuConfig.aside.items;
  }
};
</script>
