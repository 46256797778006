var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kt-menu__submenu",class:_vm.submenuClass()},[(!_vm.isMegaMenu)?_c('ul',{staticClass:"kt-menu__subnav"},[_vm._l((_vm.submenu),function(menu,i){return [_c('li',{key:i,staticClass:"kt-menu__item",class:{
                    'kt-menu__item--submenu': menu.submenu,
                    'kt-menu__item--active': _vm.activeMenu(menu)
                },attrs:{"aria-haspopup":"true","data-ktmenu-submenu-toggle":_vm.submenuToggle(menu)}},[_c('KTMenuItemText',{attrs:{"menu":menu,"parentMenu":_vm.parentMenu}}),(menu.submenu)?_c('KTMenuSubmenu',{attrs:{"submenu":menu.submenu}}):_vm._e()],1)]})],2):_vm._e(),(_vm.isMegaMenu)?_c('div',{staticClass:"kt-menu__subnav",style:({ width: ((_vm.submenu.length * 200) + "px") })},[_c('ul',{staticClass:"kt-menu__content"},[_vm._l((_vm.submenu),function(column,i){return [_c('li',{key:i,staticClass:"kt-menu__item"},[(column.title)?_c('h3',{staticClass:"kt-menu__heading kt-menu__toggle"},[_c('span',{staticClass:"kt-menu__link-text"},[_vm._v(_vm._s(column.title))])]):_vm._e(),_c('ul',{staticClass:"kt-menu__inner"},[_vm._l((column.submenu),function(menu,i){return [_c('li',{key:i,staticClass:"kt-menu__item",class:{
                                    'kt-menu__item--active': _vm.activeMenu(
                                        menu
                                    )
                                },attrs:{"aria-haspopup":"true"}},[_c('KTMenuItemText',{attrs:{"menu":menu,"parentMenu":column}})],1)]})],2)])]})],2)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }