import Apiservice from '../../common/api.service';
import {API_URL} from '../../common/config.js'

const apiService = Apiservice;


export default class WebsiteService {
    paginate(index = null) {
        let url = API_URL + 'user/website';
        if (index != null)
            url = url + '?page=' + index
        return apiService.get(url);
    }

    trashed(index = null) {
        let url = API_URL + 'user/website/trashed/all';
        if (index != null)
            url = url + '?page=' + index
        return apiService.get(url);
    }

    getAll() {
        let url = API_URL + 'user/website';
        return apiService.get(url);
    }

    create(data) {
        let url = API_URL + 'user/website'
        return apiService.post(url, data);
    }

    validate(data) {
        let url = API_URL + 'user/subdpmain/validate-domain'
        return apiService.post(url, data);
    }

    isStore(slug) {
        let url = API_URL + 'user/website/' + slug + "/is-store"
        return apiService.get(url);
    }

    get(slug) {
        let url = API_URL + 'user/website/' + slug
        return apiService.get(url);
    }

    restore(slug) {
        let url = API_URL + 'user/website/' + slug + '/restore'
        return apiService.get(url);
    }

    // createwebsiteFromStepper(data) {
    //     let url = API_URL + 'user/website/save'
    //     return apiService.post(url, data);
    // }

    update(slug, data) {
        let url = API_URL + 'user/website/' + slug
        return apiService.put(url, data);
    }

    delete(slug) {
        let url = API_URL + 'user/website/' + slug
        return apiService.delete(url);
    }

    show(slug) {
        let url = API_URL + 'user/website/' + slug
        return apiService.get(url);
    }

    troubleshoot(domainName, type) {
        let url = API_URL + `user/website/${domainName}/troubleshoot/${type}`;
        return apiService.get(url);
    }

    activateSSL(domainName, data) {
        let url = API_URL + `user/website/${domainName}/activate-ssl`;
        return apiService.put(url, data);
    }

    checkUniqueEmail(data) {
        let url = API_URL + `user/website/zoho/check-email`;
        return apiService.post(url, data);
    }

    requestZohoMailVerification(domainName, data) {
        let url = API_URL + `user/website/${domainName}/request-email-verification`;
        return apiService.put(url, data);
    }

    activateZohoMail(domainName, data) {
        let url = API_URL + `user/website/${domainName}/activate-zoho-mail`;
        return apiService.put(url, data);
    }

    activateZohoMail(domainName, data) {
        let url = API_URL + `user/website/${domainName}/activate-zoho-mail`;
        return apiService.put(url, data);
    }

    addZohoMailUser(domainName, data) {
        let url = API_URL + `user/website/${domainName}/add-zoho-mail-user`;
        return apiService.put(url, data);
    }

    domainExist(domainName) {
        let url = API_URL + `user/website/${domainName}/domain-exist`;
        return apiService.get(url);
    }

    finish(domainName) {
        let url = API_URL + `user/website/${domainName}/finish`;
        return apiService.get(url);
    }

    publish(domainName) {
        let url = API_URL + `user/website/${domainName}/publish`;
        return apiService.get(url);
    }


    remainingValididyCheck(domainName, planId) {
        let url = API_URL + `user/website/${domainName}/check-remaining-validitiy/${planId}`;
        return apiService.get(url);
    }

    disconnectWebsite(domainName) {
        let url = API_URL + `user/website/${domainName}/disconnect`;
        return apiService.get(url);
    }
}
