<template>
  <!-- begin:: Header Topbar -->
  <div class="kt-header__topbar">
    <!--begin: Search -->
    <div class="kt-header__topbar-item kt-header__topbar-item--search" id="kt_quick_search_toggle">
      <div class="kt-header__topbar-item kt-header__topbar-item--user">
        <div class="kt-header__topbar-wrapper" id="kt_user_toggle" data-toggle="dropdown">
          <div class="kt-header__topbar-user">
            <!-- <span class="kt-header__topbar-welcome kt-hidden-mobile">
              <img alt="Wallet" src="@/assets/media/icons/svg/Shopping/Wallet4.png" />
            </span>
            <span class="kt-header__topbar-username kt-hidden-mobile">Rs.0.00</span> -->
            <!--use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) -->
          </div>
        </div>
      </div>
    </div>
    <!--end: Search -->

    <!--begin: Notifications -->
     <div class="kt-header__topbar-item" id="kt_notification_toggle">
      <div class="kt-header__topbar-wrapper" data-toggle="dropdown">
        <span class="kt-header__topbar-icon kt-pulse kt-pulse--brand">
<!--          <img-->
<!--            svg-inline-->
<!--            alt="Notifications"-->
<!--            class="kt-svg-icon"-->
<!--            src="@/assets/media/icons/bell-icon-white.png"-->
<!--          />-->
          <i class="fa fa-bell kt-svg-icon" ></i>
<!--          <span class="kt-pulse__ring"></span>-->
        </span>
      </div>
      <div
        class="dropdown-menu dropdown-menu-fit dropdown-menu-xl dropdown-menu-right"
        v-on:click.stop
      >
        <form>
          <KTDropdownNotification></KTDropdownNotification>
        </form>
      </div>
    </div>
    <!--end: Notifications -->

    <!--begin: Quick Actions -->
    <!-- <div class="kt-header__topbar-item">
      <div
        class="kt-header__topbar-wrapper"
        id="kt_quick_action_toggle"
        data-toggle="dropdown"
      >
        <span class="kt-header__topbar-icon">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><rect fill="#000" opacity=".3" x="13" y="4" width="3" height="16" rx="1.5"/><rect fill="#000" x="8" y="9" width="3" height="11" rx="1.5"/><rect fill="#000" x="18" y="11" width="3" height="9" rx="1.5"/><rect fill="#000" x="3" y="13" width="3" height="7" rx="1.5"/></g></svg>
        </span>
      </div>
      <div
        class="dropdown-menu dropdown-menu-fit dropdown-menu-xl dropdown-menu-right"
        v-on:click.stop
      >
        <KTDropdownQuickAction></KTDropdownQuickAction>
      </div>
    </div>-->
    <!--end: Quick Actions -->

    <!--begin: My Cart -->
    <!-- <div class="kt-header__topbar-item">
      <div class="kt-header__topbar-wrapper" id="kt_my_cart_toggle" data-toggle="dropdown">
        <span class="kt-header__topbar-icon cart-wrapper">
          <img
            svg-inline
            class="kt-svg-icon"
            src="@/assets/media/icons/svg/Shopping/Cart4.png"
            alt="Cart"
          />
          <span class="cart-counter">{{(cart)?cart.item_length:0}}</span>
        </span>
      </div>
      <div
        class="dropdown-menu dropdown-menu-fit dropdown-menu-xl dropdown-menu-right"
        v-on:click.stop
      >
        <KTDropdownMyCart></KTDropdownMyCart>
      </div>
    </div> -->
    <!--end: My Cart -->

    <!--begin: Quick panel toggler -->
    <!-- <div
      class="kt-header__topbar-item kt-header__topbar-item--quick-panel"
      v-b-tooltip.hover.bottom="'Quick panel'"
    >
      <span class="kt-header__topbar-icon" id="kt_quick_panel_toggler_btn">
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><rect fill="#000" x="4" y="4" width="7" height="7" rx="1.5"/><path d="M5.5 13h4a1.5 1.5 0 011.5 1.5v4A1.5 1.5 0 019.5 20h-4A1.5 1.5 0 014 18.5v-4A1.5 1.5 0 015.5 13zm9-9h4A1.5 1.5 0 0120 5.5v4a1.5 1.5 0 01-1.5 1.5h-4A1.5 1.5 0 0113 9.5v-4A1.5 1.5 0 0114.5 4zm0 9h4a1.5 1.5 0 011.5 1.5v4a1.5 1.5 0 01-1.5 1.5h-4a1.5 1.5 0 01-1.5-1.5v-4a1.5 1.5 0 011.5-1.5z" fill="#000" opacity=".3"/></g></svg>
      </span>
    </div>-->
    <!--end: Quick panel toggler -->

    <!--begin: Language bar -->
    <!-- <div class="kt-header__topbar-item kt-header__topbar-item--langs">
      <div
        class="kt-header__topbar-wrapper"
        id="kt_language_toggle"
        data-toggle="dropdown"
      >
        <span class="kt-header__topbar-icon">
          <img :src="languageFlag || getLanguageFlag" alt="" />
        </span>
      </div>
      <div
        class="dropdown-menu dropdown-menu-fit dropdown-menu-right"
        v-on:click.stop
      >
        <KTDropdownLanguage
          v-on:language-changed="onLanguageChanged"
        ></KTDropdownLanguage>
      </div>
    </div>-->
    <!--end: Language bar -->

    <!--begin: User Bar -->
    <div class="kt-header__topbar-item kt-header__topbar-item--user">
      <div class="kt-header__topbar-wrapper" id="kt_user_toggle" data-toggle="dropdown">
        <div class="kt-header__topbar-user">
          <span class="kt-header__topbar-welcome">
            <img alt="Pic" src="@/assets/media/icons/svg/Communication/social.png" />
          </span>
          <span class="kt-header__topbar-username kt-hidden-mobile">
            {{
            currentUser.first_name + " " + currentUser.last_name
            }}
          </span>
          <img class="kt-hidden" alt="Pic" src="@/assets/media/users/default.jpg" />
          <!--use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) -->
        </div>
      </div>
      <div
        class="dropdown-menu dropdown-menu-fit dropdown-menu-xl dropdown-menu-right"
        v-on:click.stop
      >
        <KTDropdownUser></KTDropdownUser>
      </div>
    </div>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<script>
import { mapState, mapActions } from "vuex";
import KTSearchDefault from "@/views/theme/topbar/SearchDefault.vue";
import KTDropdownNotification from "@/views/theme/topbar/DropdownNotification.vue";
// import KTDropdownQuickAction from "@/views/theme/topbar/DropdownQuickAction.vue";
import KTDropdownMyCart from "@/views/theme/topbar/DropdownMyCart.vue";
// import KTDropdownLanguage from "@/views/theme/topbar/DropdownLanguage.vue";
import KTDropdownUser from "@/views/theme/topbar/DropdownUser.vue";
import i18nService from "@/common/i18n.service.js";
import auth from "@/store/auth.module";

export default {
  name: "KTTopbar",
  data() {
    return {
      languageFlag: "",
      languages: i18nService.languages,
      auth,
      username: auth.state.user.first_name
    };
  },
  components: {
    KTSearchDefault,
    KTDropdownNotification,
    // KTDropdownQuickAction,
    KTDropdownMyCart,
    // KTDropdownLanguage,
    KTDropdownUser
  },
  methods: {
    onLanguageChanged() {
      this.languageFlag = this.languages.find(val => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    }
  },
  computed: {
    getLanguageFlag() {
      return this.onLanguageChanged();
    },
    ...mapState({
      cart: state => state.cart.cart
    }),
    currentUser() {
      return this.$store.getters.currentUser;
    }
  }
};
</script>

<style lang="scss" scoped>
.cart-wrapper {
  position: relative;
  z-index: 1;

  .cart-counter {
    padding: 0.25rem 0.6rem;
    font-size: 10px;
    color: #fff;
    z-index: -1;
    text-align: center;
    background:#edaa53;
    border-radius: 15px;
    position: absolute;
    top: 2px;
    right: -4px;
    font-weight: 700;
  }
}
</style>
