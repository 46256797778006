<template>
  <div>
    <!--begin: Head -->
    <!-- <div
      class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    > -->
      <!-- <div class="kt-user-card__avatar">
        <img class="kt-hidden" alt="Pic" src="@/assets/media/users/default.jpg" /> -->
        <!--use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) -->
        <!-- <span class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">{{ currentUser.first_name.charAt(0)  }}</span>
      </div> -->
      <!-- <div class="kt-user-card__name">{{ currentUser.first_name + " " + currentUser.last_name }}</div> -->
      <!-- <div class="kt-user-card__badge">
        <span class="btn btn-success btn-sm btn-bold btn-font-md"
          >23 messages</span
        >
      </div>-->
    <!-- </div> -->
    <!--end: Head -->
    <!--begin: Navigation -->
    <div class="kt-notification">
      <router-link class="kt-notification__item" :to="{ name: 'myAccount'}">
        <div class="kt-notification__item-icon">
          <i class="flaticon-user kt-font-info"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">My Profile</div>
          <div class="kt-notification__item-time ">Account settings and more</div>
        </div>
      </router-link>

      <router-link :to="{ name: 'myAccount-security'}"   class="kt-notification__item">
        <div class="kt-notification__item-icon">
          <i class="flaticon-lock kt-font-info"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">Security & Password</div>
          <div class="kt-notification__item-time">Setup & Manage MFA </div>
        </div>
      </router-link >
      <!-- <a href="#" class="kt-notification__item">
        <div class="kt-notification__item-icon">
          <i class="flaticon-star kt-font-info"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">Offers</div>
          <div class="kt-notification__item-time">Deals & Coupons</div>
        </div>
      </a> -->
<!--     <router-link :to="{ name: 'reviewPersonal'}"  class="kt-notification__item">-->
<!--        <div class="kt-notification__item-icon">-->
<!--          <i class="flaticon2-rocket-1 kt-font-info"></i>-->
<!--        </div>-->
<!--        <div class="kt-notification__item-details">-->
<!--          <div class="kt-notification__item-title kt-font-bold">Reviews</div>-->
<!--          <div class="kt-notification__item-time">Seller & Buyer Ratings</div>-->
<!--        </div>-->
<!--      </router-link >-->
         <router-link :to="{ name: 'billing'}" class="kt-notification__item">
        <div class="kt-notification__item-icon">
          <i class="flaticon-price-tag kt-font-info"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">Billing</div>
          <div class="kt-notification__item-time">Billing & Statements</div>
        </div>
      </router-link>
      <div class="kt-notification__custom kt-space-between">
        <a href="#" v-on:click="onLogout()" class="btn btn-success btn-sm btn-bold">Logout</a>
      </div>
    </div>
    <!--end: Navigation -->
  </div>
</template>

<script>
import { LOGOUT } from "@/store/auth.module";

export default {
  name: "KTDropdownUser",
  props: ["userName"],
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    }
  },
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/misc/bg-1.jpg";
    },
    currentUser() {
      return this.$store.getters.currentUser;
    }
  }
};
</script>
