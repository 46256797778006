<template>
    <div>
        <div
            class="kt-head kt-head--skin-dark kt-head--fit-x kt-head--fit-b"
            :style="{ backgroundImage: `url(${backgroundImage})` }"
        >
            <h3 class="kt-head__title">
                Notifications
                <!-- <span class="ml-2 btn btn-success btn-sm btn-bold btn-font-md"
                    >23 new</span
                > -->
            </h3>
            <ul
                class="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-success kt-notification-item-padding-x"
                role="tablist"
            >
                <!-- <li class="nav-item">
                    <a
                        v-on:click="setActiveTab"
                        data-tab="0"
                        class="nav-link active"
                        data-toggle="tab"
                        href="#"
                        role="tab"
                        >Alerts</a
                    >
                </li> -->
                <!-- <li class="nav-item">
                    <a
                        v-on:click="setActiveTab"
                        data-tab="1"
                        class="nav-link"
                        data-toggle="tab"
                        href="#"
                        role="tab"
                        >Website</a
                    >
                </li>
                <li class="nav-item">
                    <a
                        v-on:click="setActiveTab"
                        data-tab="2"
                        class="nav-link"
                        data-toggle="tab"
                        href="#"
                        role="tab"
                        >Domains</a
                    >
                </li> -->
            </ul>
        </div>

        <b-tabs class="kt-hide-tabs" v-model="tabIndex">
            <b-tab active>
                <perfect-scrollbar
                    class="kt-notification kt-margin-t-10 kt-margin-b-10"
                    style="max-height: 40vh; position: relative;"
                >
                    <a href="#" class="kt-notification__item">
                        <div class="kt-notification__item-icon">
                            <i class="flaticon2-pie-chart kt-font-success"></i>
                        </div>
                        <div class="kt-notification__item-details">
                            <div class="kt-notification__item-title">
                                New customer is registered
                            </div>
                            <div class="kt-notification__item-time">
                                3 days ago
                            </div>
                        </div>
                    </a>
                </perfect-scrollbar>
            </b-tab>

            <b-tab>
                <perfect-scrollbar
                    class="kt-notification kt-margin-t-10 kt-margin-b-10"
                    style="max-height: 40vh; position: relative;"
                >
                    <a href="#" class="kt-notification__item">
                        <div class="kt-notification__item-icon">
                            <i class="flaticon2-psd kt-font-success"></i>
                        </div>
                        <div class="kt-notification__item-details">
                            <div class="kt-notification__item-title">
                                New report has been received
                            </div>
                            <div class="kt-notification__item-time">
                                23 hrs ago
                            </div>
                        </div>
                    </a>
                </perfect-scrollbar>
            </b-tab>

            <b-tab>
                <perfect-scrollbar
                    class="kt-notification kt-margin-t-10 kt-margin-b-10"
                    style="max-height: 40vh; position: relative;"
                >
                    <a href="#" class="kt-notification__item">
                        <div class="kt-notification__item-icon">
                            <i class="flaticon2-psd kt-font-success"></i>
                        </div>
                        <div class="kt-notification__item-details">
                            <div class="kt-notification__item-title">
                                New report has been received
                            </div>
                            <div class="kt-notification__item-time">
                                23 hrs ago
                            </div>
                        </div>
                    </a>
                </perfect-scrollbar>
            </b-tab>
        </b-tabs>
    </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.kt-hide-tabs > div:not(.tab-content) {
    display: none;
}
</style>

<script>
export default {
    name: "KTDropdownNotification",
    data() {
        return {
            tabIndex: 0
        };
    },
    methods: {
        setActiveTab(event) {
            const tab = event.target.closest('[role="tablist"]');
            const links = tab.querySelectorAll(".nav-link");
            // remove active tab links
            for (let i = 0; i < links.length; i++) {
                links[i].classList.remove("active");
            }

            // set clicked tab index to bootstrap tab
            this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

            // set current active tab
            event.target.classList.add("active");
        }
    },
    computed: {
        backgroundImage() {
            return process.env.BASE_URL + "assets/media/misc/bg-1.jpg";
        }
    }
};
</script>
