<template>
  <div id="kt_header_mobile" class="kt-header-mobile" v-bind:class="headerClasses">
    <div class="kt-header-mobile__logo">
      <a href="/">
        <img alt="Logo" :src="headerLogo" />
      </a>
    </div>
    <div class="kt-header-mobile__toolbar">
      <button
        class="kt-header-mobile__toggler kt-header-mobile__toggler--left"
        id="kt_aside_mobile_toggler"
      >
        <span></span>
      </button>
      <button class="kt-header-mobile__toggler" id="kt_header_mobile_toggler">
        <span></span>
      </button>
    

      <button class>
        <span class="kt-header__topbar-icon cart-wrapper m-2">
          <i class="fas fa-shopping-cart" style="color: #474e66; font-size: 18px;"></i>
          <span class="cart-counter-mobile">{{(cart)?cart.item_length:0}}</span>
        </span>
      </button>
      <button class>
        <span class="kt-header__topbar-icon cart-wrapper m-2">
          <!-- <span class="m-2 cart-counter-mobile">
            {{
            currentUser.first_name + " " + currentUser.last_name
            }}
          </span> -->
        </span>
      </button>
      <div class="kt-header__topbar-item kt-header__topbar-item--user">
        <div class="kt-header__topbar-wrapper" id="kt_user_toggle" data-toggle="dropdown">
          <div class="kt-header__topbar-user">
            <span class="kt-header__topbar-welcome">
              <img alt="Pic" src="@/assets/media/icons/svg/Communication/social.png" />
            </span>
            <span class="kt-header__topbar-username kt-hidden-mobile">
              {{
              currentUser.first_name + " " + currentUser.last_name
              }}
            </span>
            <img class="kt-hidden" alt="Pic" src="@/assets/media/users/default.jpg" />
            <!--use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) -->
          </div>
        </div>
        <div
          class="dropdown-menu dropdown-menu-fit dropdown-menu-xl dropdown-menu-right"
          v-on:click.stop
        >
          <KTDropdownUser></KTDropdownUser>
        </div>
      </div>

      <!-- <button
        class="kt-header-mobile__topbar-toggler"
        id="kt_header_mobile_topbar_toggler"
        ref="kt_header_mobile_topbar_toggler"
      >
        <i class="flaticon-more"></i>
      </button> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import KTToggle from "@/assets/js/toggle.js";
import KTDropdownUser from "@/views/theme/topbar/DropdownUser.vue";

export default {
  name: "KTHeaderMobile",
  components: {
    KTDropdownUser
  },
  mounted() {
    new KTToggle(this.$refs["kt_header_mobile_topbar_toggler"], {
      target: "body",
      targetState: "kt-header__topbar--mobile-on",
      togglerState: "kt-header-mobile__toolbar-topbar-toggler--active"
    });
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo() {
      return process.env.BASE_URL + this.layoutConfig("self.logo.dark");
    },

    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    ...mapState({
      cart: state => state.cart.cart
    }),
    currentUser() {
      return this.$store.getters.currentUser;
    },
    headerClasses() {
      const classes = this.getClasses("header_mobile");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    }
  }
};
</script>
<style scoped>
.cart-counter-mobile {
  color: rgb(255, 255, 255);
  padding: 0.25rem 0.6rem;
  font-size: 10px;
  z-index: -1;
  text-align: center;
  background: rgba(255, 255, 255, 0.28);
  border-radius: 15px;

  font-weight: 700;
}
</style>
