<template>
    <!-- begin:: Footer -->
    <div>
    <!-- <div
        class="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop"
        id="kt_footer"
    >
      <div class="kt-footer__copyright text-right">
        &nbsp;&nbsp; &nbsp;&copy;&nbsp;
        <a
          href="https://yelko.net"
          target="_blank"
          class="kt-link ml-1"
        >
          &nbsp;Yelko
        </a>
      </div>
      <div class="kt-footer__menu"> -->
        <!-- <a
          href="https://yelko.net"
          target="_blank"
          class="kt-footer__menu-link kt-link"
          >contact@yelko.com</a
        >
        <a
          href="https://yelko.net"
          target="_blank"
          class="kt-footer__menu-link kt-link"
          >9802308000</a
        > -->
      <!-- </div>
    </div> -->
    <!-- end:: Footer -->
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "KTFooter",
    methods: {
        /**
         * Check if footer container is fluid
         * @returns {boolean}
         */
        isFluid() {
            return this.layoutConfig("footer.self.width") === "fluid";
        }
    },
    computed: {
        ...mapGetters(["layoutConfig"])
    }
};
</script>
