<template>
  <!-- begin:: Mycart -->
  <form>
    <!-- begin:: Mycart -->
    <div class="kt-mycart">
      <div class="kt-mycart__head kt-head" :style="{ backgroundImage: `url(${backgroundImage})` }">
        <div class="kt-mycart__info">
          <span class="kt-mycart__icon">
            <i class="flaticon2-shopping-cart-1 kt-font-success"></i>
          </span>
          <h3 class="kt-mycart__title">{{cart?'My Cart':'Cart Is Empty'}}</h3>
        </div>
        <div class="kt-mycart__button" v-if="cart">
          <button type="button" class="btn btn-success btn-sm">{{cart.item_length}}</button>
        </div>
      </div>

      <div class="kt-mycart__body" v-if="cart">
        <perfect-scrollbar style="max-height: 40vh; position: relative;">
          <div class="kt-mycart__item" v-for="(item,index) in cart.membership_items" :key="index">
            <div class="kt-mycart__container">
              <div class="kt-mycart__info">
                <a
                  href="#"
                  class="kt-mycart__title"
                  v-if="item.organisation_title"
                >{{item.organisation_title}}({{item.item_type}})</a>
                 <a
                  href="#"
                  class="kt-mycart__title"
                  v-else
                >{{item.website_title}}({{item.item_type}})</a>
                <span class="kt-mycart__desc">{{item.purchase_type}}</span>

                <div class="kt-mycart__action">
                  <span class="kt-mycart__price">{{store.currency_symbol}}{{item.actual_price}}</span>
                  <!-- <span class="kt-mycart__text">for</span> -->
                  <!-- <span class="kt-mycart__quantity">7</span> -->
                  <a
                    href="#"
                    class="btn btn-label-success btn-icon"
                    @click="deleteCartItem(item.id)"
                  >
                    <i class="fas fa-trash"></i>
                  </a>
                  <!-- <a href="#" class="btn btn-label-success btn-icon">+</a> -->
                </div>
              </div>

              <!-- <a href="#" class="kt-mycart__pic">
                <img src="@/assets/media/products/product9.jpg" title />
              </a>-->
            </div>
          </div>
             <div class="kt-mycart__item" v-for="(item,index) in cart.domain_items" :key="index">
            <div class="kt-mycart__container">
              <div class="kt-mycart__info">
                <a
                  href="#"
                  class="kt-mycart__title"
                  v-if="item.domain_name"
                >{{item.domain_name}}({{item.item_type}})</a>
                <span class="kt-mycart__desc">{{item.purchase_type}}</span>

                <div class="kt-mycart__action">
                  <span class="kt-mycart__price">{{store.currency_symbol}}{{item.actual_price}}</span>
                  <!-- <span class="kt-mycart__text">for</span> -->
                  <!-- <span class="kt-mycart__quantity">7</span> -->
                  <a
                    href="#"
                    class="btn btn-label-success btn-icon"
                    @click="deleteCartItem(item.id)"
                  >
                    <i class="fas fa-trash"></i>
                  </a>
                  <!-- <a href="#" class="btn btn-label-success btn-icon">+</a> -->
                </div>
              </div>

              <!-- <a href="#" class="kt-mycart__pic">
                <img src="@/assets/media/products/product9.jpg" title />
              </a>-->
            </div>
          </div>
        </perfect-scrollbar>
      </div>

      <div class="kt-mycart__footer" v-if="cart">
        <div class="kt-mycart__section">
          <div class="kt-mycart__subtitel">
            <span>Sub Total</span>
            <span v-if="cart.discount_amount">Discount [{{cart.coupon_code}}]</span>
            <span>Tax</span>
            <span>Total</span>
          </div>

          <div class="kt-mycart__prices">
            <span>{{store.currency_symbol}}{{cart.sub_total_amount}}</span>
            <span v-if="cart.discount_amount">{{store.currency_symbol}}{{cart.discount_amount}}</span>
            <span>{{store.currency_symbol}}{{cart.tax_amount}}</span>
            <span class="kt-font-brand">{{store.currency_symbol}}{{cart.total_amount}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 col-md-6 col-lg-6">
            <div class="kt-mycart__button kt-align-left">
              <!-- <router-link to="/cart" class="btn btn-primary btn-sm">Detail</router-link> -->
            </div>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-6">
            <div class="kt-mycart__button kt-align-right">
              <router-link to="/cart" class="btn btn-primary btn-sm">Checkout Now</router-link>
              <!-- <button type="button" class="btn btn-primary btn-sm">Checkout</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end:: Mycart -->
  </form>
  <!-- end:: Mycart -->
</template>

<script>
import { mapState, mapActions } from "vuex";
import NotifyService from "@/common/notify.service";

export default {
  name: "KTDropdownMyCart",
  computed: {
    ...mapState({
      cart: state => state.cart.cart,
    }),
      currentUser() {
          return this.$store.getters.currentUser;
      },
      store() {
          return this.$store.getters.currentUser.store_config;
      },
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/misc/bg-1.jpg";
    }
  },
  created() {
    this.GET_CART();

  },
  methods: {
    ...mapActions(["GET_CART", "DELETE_CART_ITEM"]),
    deleteCartItem(id) {
      this.DELETE_CART_ITEM(id)
        .then(response => {
          this.$snotify.success("Deleted from cart");
        })
        .catch(error => {
          this.$snotify.error("Error occured");
        });
    }
  }
};
</script>
